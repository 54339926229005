import React from 'react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import Layout from '../components/layout'

import { container } from '../styles/theme'

const NotFoundPage = () => (
  <Layout>
    <Helmet title="404 - Page Not Found" />
    <Container>
      <h1 className="heading heading--h1">Page Not Found</h1>
    </Container>
  </Layout>
)

const Container = styled.div`
  ${container};
`

export default NotFoundPage
